import { useTranslation } from 'react-i18next';
import './Identification.scss';
import { Box, DataInBox } from 'components';
import {
  TransDetailResourceDto,
  TransDetailWasteDto,
} from 'domain/backend-service';
interface Props {
  data: TransDetailResourceDto | TransDetailWasteDto;
}
export const Identification = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <div className="insideBoxColumns">
        <div className="boxColumns">
          <DataInBox
            label={t('chain.identification.project')}
            data={data.projectName!}
          />
          <div className="insideBoxData">
            <span>Información de blockchain</span>
            <a className="insideBoxData" href={data.blockchainUrl}>
              {data.blockchainAddress}
            </a>
          </div>

          <DataInBox
            label={t('chain.identification.company')}
            data={data.originCompany!}
          />
        </div>
        {data.qrCode != null && (
          <img src={data.qrCode} alt="qr" width={93} height={93} />
        )}
      </div>

      <div
        className="insideBoxColumns"
        style={{ justifyContent: 'space-between' }}
      >
        <DataInBox label={t('chain.identification.lotId')} data={data.id!} />
        {data.type === 'RESOURCE' && (
          <>
            <DataInBox
              label={t('chain.identification.brand')}
              data={data.brand!}
            />
            <DataInBox
              label={t('chain.identification.model')}
              data={data.model!}
            />
          </>
        )}
      </div>
      <DataInBox label={t('chain.identification.name')} data={data.name!} />
      <DataInBox
        label={t('chain.identification.description')}
        data={data.description!}
      />

      <DataInBox
        label={t('chain.identification.reference')}
        data={data.reference}
      />

      <DataInBox
        label={t('chain.identification.possibleExits')}
        data={data.possibleOutcome}
      />
    </Box>
  );
};

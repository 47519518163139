import { Skeleton } from './skeleton';

export const SkeletonBoxChainDetail = ({ line = true }: { line?: boolean }) => (
  <div className="chainRow" style={{ paddingBottom: '0' }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Skeleton width={60} height={60} />
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div style={{ flex: 1 }} />
        {line === true ? (
          <div
            style={{
              flex: 1,
              height: '100%',
              borderLeftWidth: '2px',
              borderLeftColor: '#c3beb6',
              borderLeftStyle: 'dashed',
            }}
            className={'chainRowLineDashed'}
          />
        ) : (
          <div
            style={{
              flex: 1,
              height: '100%',
            }}
          />
        )}
      </div>
    </div>
    <div
      className="chainText"
      style={{
        paddingBottom: '4%',
      }}
    >
      <Skeleton height={15} width={'40%'} />
      <Skeleton height={5} width={'20%'} />
      <Skeleton height={5} width={'20%'} />
      <Skeleton height={5} width={'20%'} />
      <Skeleton height={5} width={'20%'} />
    </div>
  </div>
);

import { useTranslation } from 'react-i18next';

import './ChainTypes.scss';
import { Identification, Location, Equipment, ChainDetail } from './components';

import { useParams } from 'react-router-dom';
import { useResource } from 'api/use-resource';
import {
  SkeletonEquipment,
  SkeletonIdentification,
  SkeletonLocation,
} from 'components/loading';
import { SkeletonChainDetail } from 'components/loading/skeleton-chaindetail';
// import { SkeletonChainDetail } from 'components/loading/skeleton-chaindetail';

export const Resource = () => {
  const { t } = useTranslation();
  let { resourceId } = useParams();
  const { isLoading, data } = useResource(resourceId!);
  return (
    <div id="resource">
      <h1>{t('resource.title')}</h1>
      <div className="wrapper">
        <div id="identification">
          <h2>{t('chain.identification.title')}</h2>
          {isLoading ? (
            <SkeletonIdentification />
          ) : (
            <Identification data={data!} />
          )}
        </div>
        <div id="equipment">
          <h2>{t('chain.equipment.title')}</h2>
          {isLoading ? <SkeletonEquipment /> : <Equipment data={data!} />}
        </div>
        <div id="location">
          <h2>{t('chain.location.title')}</h2>
          {isLoading ? <SkeletonLocation /> : <Location data={data!} />}
        </div>
        <div id="chainDetail">
          <h2>{t('chain.chainDetail.title')}</h2>
          {isLoading ? <SkeletonChainDetail /> : <ChainDetail data={data!} />}
        </div>
      </div>
    </div>
  );
};

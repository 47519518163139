import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useBackend } from 'context/backend-context';
import { TransDetailWasteDto } from 'domain/backend-service';

export function useWaste(
  chainCode: string,
  config?: UseQueryOptions<TransDetailWasteDto, AxiosError>,
) {
  const api = useBackend();
  return useQuery<TransDetailWasteDto, AxiosError>(
    ['info', chainCode],
    () => api.getWaste(chainCode),
    config,
  );
}

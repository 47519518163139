import type { AxiosInstance } from 'axios';
import axios from 'axios';
import type React from 'react';
import { createContext, useContext, useRef } from 'react';

// this uses an axios.create that will be discarded later
// TODO: is there a better approach?
/** The axios context in use by the app */
const AxiosContext = createContext<AxiosInstance | null>(null);

/** Provides access to the axios context for http requests */
export const useAxios = () => useContext(AxiosContext)!;

export function AxiosProvider({ children }: { children: React.ReactNode }) {
  const instance = useRef(axios.create({ transformResponse: data => data }));

  return (
    <AxiosContext.Provider value={instance.current}>
      {children}
    </AxiosContext.Provider>
  );
}

import { useTranslation } from 'react-i18next';

import { Box, DataInBox } from 'components';
import { LerCodeDto, TransDetailWasteDto } from 'domain/backend-service';

interface Props {
  data: TransDetailWasteDto;
}
export const Composition = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <div className="insideBoxData">
        <span>LER</span>
        {data.lerCodes?.map((ler, index) => (
          <div key={index}>
            <label>{ler.code}</label> - <label>{ler.name}</label>
          </div>
        ))}
      </div>
      <DataInBox
        label={t('chain.composition.inert')}
        data={
          data.inert === true
            ? t('chain.composition.true')
            : t('chain.composition.false')
        }
      />
      <DataInBox
        label={t('chain.composition.hazardous')}
        data={
          data.hazardous === true
            ? t('chain.composition.true')
            : t('chain.composition.false')
        }
      />
    </Box>
  );
};

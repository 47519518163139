import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import config from 'config';

interface Props {
  width: number | string;
  height: number;
  latitude: number;
  longitude: number;
  zoom: number;
}
export const MapComponent = ({
  width,
  height,
  latitude,
  longitude,
  zoom,
}: Props) => {
  const [position, setPosition] = useState<google.maps.LatLng | undefined>();
  useEffect(() => {
    const timer = setTimeout(() => {
      setPosition(new google.maps.LatLng(latitude, longitude));
    }, 1000);
    return () => clearTimeout(timer);
  }, [latitude, longitude]);

  return (
    <Wrapper apiKey={config.googleApi}>
      <Map
        width={width}
        height={height}
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
      >
        {position != null && <Marker position={position} />}
      </Map>
    </Wrapper>
  );
};

const Map = ({
  width,
  height,
  latitude,
  longitude,
  zoom,
  children,
}: Props & { children: ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center: { lat: latitude, lng: longitude },
          zoom,
          disableDefaultUI: true,
        }),
      );
    }
  }, [ref, latitude, longitude, map, zoom]);

  return (
    <>
      <div ref={ref} style={{ width, height, marginTop: -14 }} />
      {Children.map(children, child => {
        if (isValidElement(child)) {
          // @ts-ignore
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options: google.maps.MarkerOptions) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

import './DataInBox.scss';

interface Props {
  label: string;
  data?: string | number;
}
export const DataInBox = ({ label, data }: Props) => (
  <div className="insideBoxData">
    <span>{label}</span>
    {data ? data : '-----'}
  </div>
);

import { CSSProperties, useState } from 'react';

import './ChainDetail.scss';
import { ICON_TYPE } from 'config/constants';
import { ReactComponent as Connect } from 'assets/images/Connect.svg';
// import { ReactComponent as SeeMore } from 'assets/icons/SeeMore.svg';
// import { ReactComponent as SeeLess } from 'assets/icons/SeeLess.svg';

import { ChainOperation } from './ChainOperation';
import {
  TransChainBlockDto,
  TransDetailResourceDto,
  TransDetailWasteDto,
} from 'domain/backend-service';

interface Props {
  data: TransDetailResourceDto | TransDetailWasteDto;
}
export const ChainDetail = ({ data }: Props) => {
  // const { t } = useTranslation();

  const [showChild, setShowChild] = useState(false);
  // const handleShowChild = () => {
  //   setShowChild(prev => !prev);
  // };
  console.log(setShowChild);
  const icon = ICON_TYPE[data.type!];
  // const childOperations = data.blocks![data.blocks!.length - 1];
  // const arrayOperations = showChild ? childOperations : undefined;

  return (
    <div id="chainDetail">
      <div className="wrapper">
        <div className="chainHeader" style={{ backgroundColor: icon.color }}>
          <icon.Icon width={61} height={61} />
          <div>
            <span>{data.name}</span>
            {data.quantity} {data.units}
          </div>
        </div>
        <ChainBody operations={data.blocks!} showChild={showChild} />
        {/*{childOperations != null && (
          <div className="chainSeeMore" onClick={handleShowChild}>
            {showChild ? <SeeLess /> : <SeeMore />}
            {t(`chain.chainDetail.${showChild ? 'seeLess' : 'seeMore'}`)}
          </div>
        )}*/}
        {/* {arrayOperations != null &&
          arrayOperations.map((operas, index) => (
            <ChainBody
              key={index}
              child
              lastChild={arrayOperations.length - 1 === index}
              operations={operas}
            />
          ))} */}
        {/* {childOperations != null && showChild && (
          <div className="chainSeeMore" onClick={handleShowChild}>
            {showChild ? <SeeLess /> : <SeeMore />}
            {t(`chain.chainDetail.${showChild ? 'seeLess' : 'seeMore'}`)}
          </div>
        )} */}
      </div>
    </div>
  );
};

const ChainBody = ({
  operations,
  child = false,
  lastChild = false,
  showChild = false,
}: {
  operations: TransChainBlockDto[];
  child?: boolean;
  lastChild?: boolean;
  showChild?: boolean;
}) => {
  const childStyle: CSSProperties = { paddingLeft: 84, paddingTop: 36 };

  return (
    <div className="chainBody" style={child ? childStyle : undefined}>
      {child && (
        <div className="chainBodyConnect">
          <Connect width={72} />
        </div>
      )}
      {child && !lastChild && <div className="chainBodyLine" />}
      {operations.map((operation, index) => {
        const existNext = operations[index + 1] != null;
        const isFather = operation.operations != null && showChild;
        const dashed = existNext && operations[index + 1].operations != null;

        return (
          <ChainOperation
            key={operation.id}
            existNext={existNext}
            isFather={isFather}
            dashed={dashed}
            value={operation.value}
            color={operation.color}
            data={operation!}
          />
        );
      })}
    </div>
  );
};

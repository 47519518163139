import { useTranslation } from 'react-i18next';

import './ChainTypes.scss';
import {
  Identification,
  Location,
  Composition,
  ChainDetail,
} from './components';

import { useParams } from 'react-router-dom';
import { SkeletonIdentification, SkeletonLocation } from 'components/loading';
import { SkeletonChainDetail } from 'components/loading/skeleton-chaindetail';
import { SkeletonComposition } from 'components/loading/skeleton-composition';
import { useWaste } from 'api/use-waste';

export const Waste = () => {
  const { t } = useTranslation();
  let { wasteId } = useParams();
  const { isLoading, data } = useWaste(wasteId!);
  console.log('DATOS RESIDUO', data);
  return (
    <div id="resource">
      <h1>{t('waste.title')}</h1>
      <div className="wrapper">
        <div id="identification">
          <h2>{t('chain.identification.title')}</h2>
          {isLoading ? (
            <SkeletonIdentification />
          ) : (
            <Identification data={data!} />
          )}
        </div>
        <div id="composition">
          <h2>{t('chain.composition.title')}</h2>
          {isLoading ? <SkeletonComposition /> : <Composition data={data!} />}
        </div>
        <div id="location">
          <h2>{t('chain.location.title')}</h2>
          {isLoading ? <SkeletonLocation /> : <Location data={data!} />}
        </div>
        <div id="chainDetail">
          <h2>{t('chain.chainDetail.title')}</h2>
          {isLoading ? <SkeletonChainDetail /> : <ChainDetail data={data!} />}
        </div>
      </div>
    </div>
  );
};

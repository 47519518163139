import { Routes, Route, Navigate } from 'react-router-dom';

import { App } from './App';
import { Login, Project } from 'pages';
import { Waste } from 'pages/ChainTypes/Waste';
import { Resource } from 'pages/ChainTypes/Resource';

export const Navigation = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<App />}>
        <Route path="project/:projectId" element={<Project />} />
        <Route path="resource/:resourceId" element={<Resource />} />
        <Route path="waste/:wasteId" element={<Waste />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

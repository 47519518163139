import { Box } from 'components/Box';
import { Skeleton } from './components';

export const SkeletonComposition = () => {
  return (
    <Box>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}>
        <div style={{ paddingBottom: '10px' }}>
          <Skeleton width={'40%'} />
        </div>
        {Array.from(Array(5), (_, i) => (
          <div
            key={i}
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '20px',
              paddingBottom: '6px',
            }}
          >
            <Skeleton width={'20%'} />
            <Skeleton width={'80%'} />
          </div>
        ))}
      </div>
    </Box>
  );
};

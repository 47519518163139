import React from 'react';
import './skeleton.scss';

interface SkeletonProps {
  color?: string;
  variant?: 'box' | 'circle';
  width?: string | number;
  height?: string | number;
  radius?: number;
}

export const Skeleton: React.FC<SkeletonProps> = ({
  color = '#DED9D9',
  width = '100%',
  variant,
  height = 10,
  radius,
}) => {
  let backgroundColor = color;
  let borderRadius = radius ?? 0;
  if (variant === 'circle' && height) {
    borderRadius =
      typeof height === 'string' ? parseInt(height, 10) / 2 : height / 2;
  }

  return (
    <div
      className="loading"
      style={{
        backgroundColor,
        width,
        height,
        borderRadius,
      }}
    />
  );
};

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useBackend } from 'context/backend-context';
import { ProjectReadDto } from 'domain/backend-service';

export function useProject(
  projectCode: number,
  config?: UseQueryOptions<ProjectReadDto, AxiosError>,
) {
  const api = useBackend();
  return useQuery<ProjectReadDto, AxiosError>(
    ['info', projectCode],
    () => api.getById_1(projectCode),
    config,
  );
}

import { Box } from 'components/Box';
import { Skeleton } from './components/skeleton';
import { SkeletonDataInBox } from './components/skeleton-datainbox';

export const SkeletonLocation = () => {
  return (
    <Box>
      <SkeletonDataInBox />
      <Skeleton width="100%" height={221} />
    </Box>
  );
};

import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import './Modal.scss';
import { ReactComponent as IconClose } from 'assets/icons/Close.svg';

type Props = {
  children: ReactNode;
  width: number;
  // height: number;
  onClose: () => void;
};

export const Modal = ({
  children,
  width,
  // height,
  onClose,
}: Props) => {
  return ReactDOM.createPortal(
    <div id="modal">
      <div className="content" style={{ maxWidth: width }}>
        <div className="close" onClick={onClose}>
          <IconClose />
        </div>
        {children}
      </div>
    </div>,
    document.body,
  );
};

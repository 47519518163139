import { useTranslation } from 'react-i18next';

import './Project.scss';
import {
  // Chains,
  Identification,
  Location,
} from './components';

import { useProject } from 'api/use-project';
import { useParams } from 'react-router-dom';
import {
  // SkeletonChains,
  SkeletonIdentification,
  SkeletonLocation,
} from 'components/loading';

export const Project = () => {
  const { t } = useTranslation();
  let { projectId } = useParams();
  const { isLoading, data } = useProject(parseInt(projectId!));
  return (
    <div id="project">
      {isLoading ? <SkeletonIdentification /> : <h1>{t('project.title')}</h1>}
      <div className="wrapper">
        <div id="identification">
          {isLoading ? (
            <SkeletonIdentification />
          ) : (
            <Identification data={data!} />
          )}
        </div>
        <div id="location">
          {isLoading ? <SkeletonLocation /> : <Location data={data!} />}
        </div>
        {/* <div id="chains">
          {isLoading ? <SkeletonChains /> : <Chains data={data?.chains!} />}
        </div> */}
      </div>
    </div>
  );
};

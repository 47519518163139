import { Skeleton } from './components/skeleton';
import './../../pages/ChainTypes/components/ChainDetail.scss';
import { SkeletonBoxChainDetail } from './components/skeleton-boxChainDetail';
export const SkeletonChainDetail = () => {
  return (
    <div className="wrapper">
      <div className="chainHeader">
        <Skeleton width={61} height={61} />
        <div>
          <div style={{ paddingBottom: '10px' }}>
            <Skeleton height={25} width={300} />
          </div>
          <div>
            <Skeleton height={15} width={40} />
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: 28, paddingTop: 16 }}>
        {Array.from(Array(3), (_, i) => (
          <SkeletonBoxChainDetail key={i} />
        ))}
        <SkeletonBoxChainDetail line={false} />
      </div>
    </div>
  );
};

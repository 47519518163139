import { BrowserRouter } from 'react-router-dom';

import { ScrollToTop } from './components';
import { Navigation } from './Navigation';
import { AxiosProvider } from 'context/axios-context';
import { BackendProvider } from 'context/backend-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient();

export const Root = () => {
  return (
    <AxiosProvider>
      <BackendProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ScrollToTop />
            <Navigation />
          </BrowserRouter>
        </QueryClientProvider>
      </BackendProvider>
    </AxiosProvider>
  );
};

import { useTranslation } from 'react-i18next';

import { Box, DataInBox } from 'components';
import {
  TransDetailResourceDto,
  TransDetailWasteDto,
} from 'domain/backend-service';

interface Props {
  data: TransDetailResourceDto | TransDetailWasteDto;
}
export const Equipment = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Box>
      <DataInBox
        label={t('chain.equipment.use')}
        data={`${t(`resourceStatus.${data.resourceStatus}`)}`}
      />
      <DataInBox
        label={t('chain.equipment.reusable')}
        data={
          data.reusable === true
            ? t('chain.equipment.true')
            : t('chain.equipment.false')
        }
      />
    </Box>
  );
};

import './Operation.scss';

export const Operation = ({
  value,
  color,
  size,
}: {
  value: string;
  color: string;
  size?: number;
}) => {
  const styleSize = size ? { width: size, height: size } : {};
  return (
    <div
      className="activityOperation"
      style={{ backgroundColor: color, ...styleSize }}
    >
      {value}
    </div>
  );
};

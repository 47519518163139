import { useTranslation } from 'react-i18next';

import { Box, DataInBox, MapComponent } from 'components';
import { ProjectReadDto } from 'domain/backend-service';

interface Props {
  data: ProjectReadDto;
}
export const Location = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <h2>{t('project.location.title')}</h2>
      <Box>
        <DataInBox
          label={t('project.location.ubication')}
          data={data.addressDescription!}
        />
        <MapComponent
          width="100%"
          height={221}
          latitude={data.lat!}
          longitude={data.lon!}
          zoom={14}
        />
      </Box>
    </>
  );
};

import { AppConfiguration } from './model';

const production: AppConfiguration = {
  production: true,
  api: {
    url: 'https://api.traceparent.com',
  },
  googleApi: 'AIzaSyDIy0CYsijHNpFy_nJ14guPfAv1GfVDLIA',
};

export default production;

import { useTranslation } from 'react-i18next';

import { Box, DataInBox, MapComponent } from 'components';
import {
  ProjectReadDto,
  TransDetailResourceDto,
  TransDetailWasteDto,
} from 'domain/backend-service';

interface Props {
  data: TransDetailWasteDto | TransDetailResourceDto | ProjectReadDto;
}
export const Location = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <Box>
      <DataInBox
        label={t('chain.location.ubication')}
        data={data.addressDescription}
      />
      <MapComponent
        width="100%"
        height={221}
        latitude={data.lat!}
        longitude={data.lon!}
        zoom={14}
      />
    </Box>
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ReactComponent as IconWorld } from 'assets/icons/World.svg';
import { ReactComponent as IconSelect } from 'assets/icons/Select.svg';
import { ReactComponent as IconLogo } from 'assets/images/logoNotText.svg';
import './Header.scss';
import { Link } from 'react-router-dom';

export const Header = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language.slice(0, 2));
  const languages = [
    { id: 'en', name: t('header.en') },
    { id: 'es', name: t('header.es') },
  ];

  const toggleLanguage = () => {
    setToggleMenuLanguage(!toggleMenuLanguage);
  };

  const clickedButtonLanguageOption = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    setActiveButtonLanguage(lang);
  };
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const [toggleMenuLanguage, setToggleMenuLanguage] = useState(false);
  const [activeButtonLanguage, setActiveButtonLanguage] = useState(
    i18n.language,
  );

  return (
    <div id="header">
      <Link to="/">
        <IconLogo className="iconLogo" />
      </Link>

      <div className="nav_language">
        <button onClick={toggleLanguage} className="button_language">
          <IconWorld className="iconWorld" />
          {t('header.language')}: <span>{language} </span>
          <IconSelect className="iconSelect" />
        </button>
        {toggleMenuLanguage && (
          <div className="nav_MenuLanguage">
            {languages.map(lang => (
              <button
                name={lang.id}
                onClick={() => clickedButtonLanguageOption(lang.id)}
                className={
                  activeButtonLanguage === lang.id
                    ? 'button_languageOption button_languageOption_active'
                    : 'button_languageOption'
                }
              >
                {lang.name}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

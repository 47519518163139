import config from '../config';
import { Client } from '../domain/backend-service';
import { useAxios } from './axios-context';
import type React from 'react';
import { createContext, useContext, useRef } from 'react';

/** The client instance to use when requesting the API */
const BackendContext = createContext<Client | null>(null);

export const useBackend = () => useContext(BackendContext)!;

export function BackendProvider({ children }: { children: React.ReactNode }) {
  const axios = useAxios();
  const client = useRef(new Client(config.api.url, axios));

  return (
    <BackendContext.Provider value={client.current}>
      {children}
    </BackendContext.Provider>
  );
}

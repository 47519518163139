import { useTranslation } from 'react-i18next';

import { Box, DataInBox } from 'components';
import { ProjectReadDto } from 'domain/backend-service';

interface Props {
  data: ProjectReadDto;
}
export const Identification = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <div id="identification">
      <h2>{t('project.identification.title')}</h2>
      <Box>
        <DataInBox
          label={t('project.identification.project')}
          data={data.companyName!}
        />
        <DataInBox
          label={t('project.identification.company')}
          data={data.id!}
        />
        <div className="insideBoxColumns">
          <DataInBox
            label={t('project.identification.dateFirst')}
            data={data.id!}
          />
          <DataInBox
            label={t('project.identification.dateLast')}
            data={data.id!}
          />
        </div>
        <DataInBox
          label={t('project.identification.description')}
          data={data.description!}
        />
      </Box>
    </div>
  );
};

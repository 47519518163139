import { Box } from 'components/Box';
import { SkeletonDataInBox } from './components/skeleton-datainbox';

export const SkeletonEquipment = () => {
  return (
    <Box>
      {Array.from(Array(2), (_, i) => (
        <SkeletonDataInBox key={i} />
      ))}
    </Box>
  );
};

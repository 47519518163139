import { ReactNode } from 'react';

import './Box.scss';

interface Props {
  children: ReactNode;
  width?: number | string;
  height?: number | string;
}
export const Box = ({ children, width, height }: Props) => (
  <div className="box" style={{ width, height }}>
    {children}
  </div>
);

import { Box } from 'components/Box';
import { Skeleton, SkeletonDataInBox } from './components';

export const SkeletonIdentification = () => {
  return (
    <Box>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <div style={{ paddingBottom: '20px' }}>
            <SkeletonDataInBox />
          </div>
          <SkeletonDataInBox />
        </div>
        <div>
          <Skeleton width={100} height={100} />
        </div>
      </div>
      {Array.from(Array(4), (_, i) => (
        <SkeletonDataInBox key={i} />
      ))}
    </Box>
  );
};

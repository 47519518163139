import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Operation } from 'components';
import { ReactComponent as SeeMore } from 'assets/icons/SeeMore.svg';
import { ReactComponent as SeeLess } from 'assets/icons/SeeLess.svg';
import { ReactComponent as PDF } from 'assets/icons/PDF.svg';
import { TransChainBlockDto } from 'domain/backend-service';
import moment from 'moment';
interface ChainOperationProps {
  existNext: boolean;
  isFather: boolean;
  dashed: boolean;
  data: TransChainBlockDto;
  value: string;
  color: string;
}

const colorBlock = (code?: string) => {
  if (code == null) {
    return '';
  }
  switch (code.charAt(0)) {
    case 'A':
      return '#F3F2EE';
    case 'D':
      return '#FFB2A4';
    case 'R':
    default:
      return code[2] != null && code[2] === '4' ? '#78A5FF' : '#78FFE5';
  }
};

export const ChainOperation = ({
  existNext,
  isFather,
  dashed,
  value,
  color,
  data,
}: ChainOperationProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(-1);
  const [showDocumentation, setShowDocumentation] = useState(false);
  const handleShowDocumentation = () => setShowDocumentation(prev => !prev);
  return (
    <div className="chainRow">
      {(existNext || isFather) && (
        <div className={`chainRowLine${dashed ? ' chainRowLineDashed' : ''}`} />
      )}

      <div
        className="chainRowIcon"
        style={{ backgroundColor: colorBlock(data.operationCode) }}
      >
        {data.operationCode}
      </div>
      <div className="chainOperation">
        <Operation value={value} color={color} size={48} />
      </div>
      <div className="chainText">
        <div>{t(`blocks.${data.operationCode!.replace('.', '_')}`)} </div>
        <a className="chainText" href={data.blockchainUrl}>
          <span>{data.blockchainTransactionId}</span>
        </a>
        <span>
          {t('chain.chainDetail.company')}: {data.companyCode}
        </span>
        <span>
          {t('chain.chainDetail.operator')}: {data.operatorCode}
        </span>
        <span>
          {t('chain.chainDetail.location')}:
          <a
            // href={`https://maps.google.com/?ll=${-82.359685&z=14}`}

            href={`https://maps.google.com/?q=${data.lat},${data.lon}`}
            target="blank"
            className="linkUbication"
          >
            {data.addressDescription
              ? data.addressDescription
              : `${data.lat} - ${data.lon}`}
          </a>
        </span>
        <span>
          {t('chain.chainDetail.date')}:
          {moment(data.createdOn).format('DD-MM-YYYY h:mm')}
        </span>

        {((data.images && data.images.length > 0) ||
          (data.documents && data.documents.length > 0)) && (
          <div
            className="documentationSeeMore"
            onClick={() => handleShowDocumentation()}
          >
            {t(
              `chain.chainDetail.${
                showDocumentation ? 'seeLess' : 'seeDocumentation'
              }`,
            )}
            {showDocumentation ? <SeeLess /> : <SeeMore />}
          </div>
        )}

        {showDocumentation && (
          <>
            {data.images != null && data.images.length > 0 && (
              <div className="pictures">
                <label>{t('chain.chainDetail.pictures')}</label>
                <div>
                  {data.images?.map((picture, index) => (
                    <img
                      key={index}
                      src={picture.url}
                      alt={`Document ${index + 1}`}
                      onClick={() => setOpen(index)}
                    />
                  ))}
                </div>
              </div>
            )}
            {data.documents != null && data.documents.length > 0 && (
              <div className="pictures">
                <label>{t('chain.chainDetail.documents')}</label>
                <div>
                  {data.documents?.map((doc, index) => (
                    <div className="boxPdf" key={index}>
                      <button>
                        <a
                          href={doc.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          download={doc.originalName}
                        >
                          <PDF />
                          <label>{doc.originalName}</label>
                        </a>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {data.images != null && open !== -1 && (
              <Modal width={600} onClose={() => setOpen(-1)}>
                <img
                  src={data.images[open].url}
                  alt={data.images[open].originalName}
                  width="100%"
                  height="100%"
                  style={{ objectFit: 'contain' }}
                />
              </Modal>
            )}
          </>
        )}
      </div>
    </div>
  );
};

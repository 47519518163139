import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useBackend } from 'context/backend-context';
import { TransDetailResourceDto } from 'domain/backend-service';

export function useResource(
  chainCode: string,
  config?: UseQueryOptions<TransDetailResourceDto, AxiosError>,
) {
  const api = useBackend();
  return useQuery<TransDetailResourceDto, AxiosError>(
    ['info', chainCode],
    () => api.getResource(chainCode),
    config,
  );
}
